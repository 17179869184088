<template>
    <div class="addVue">
        <template v-for="(item, index) in detailSet">
            <div :key="index" :class="[item.fieldCategory == '4' ? 'list1' : 'list', item.isDisplayHidden ? 'displayHidden' : '']">
                <template v-if="item.fieldGroup == 0">
                    <template v-if="item.controlTypeId == 0 || item.controlTypeId == 14">
                        <p class="contactTitle" :key="index">
                            <span>{{ item.cnFieldCaption }}</span>
                            <span class="contactTitle-warn" v-if="item.cnFieldCaption === '最终报价'">根据新品开发原则：新品订单成交净价须在70折以上！</span>
                        </p>
                    </template>
                    <template v-else>
                        <div class="listBox">
                            <span class="title">{{item.cnFieldCaption}}</span>
                            <component :sysBoxValue="sysBoxValue" :currency="currency" :AONIcustSort="AONIcustSort" :type="type" :cusBoxValue="cusBoxValue" :dataId="'control' + index" :nameId="item.fieldName" :optCode="optCode" :moduleCode="moduleCode" :key="index" ref="control" v-bind:is="'ControlsShow' + item.controlTypeId" :itemData="item" :value="{value: formData[item.fieldName]}" :items="formData"></component>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="listBox">
                        <span class="title">{{item.cnFieldCaption}}</span>
                        <component :sysBoxValue="sysBoxValue" :optCode="optCode" :type="type" :moduleCode="moduleCode" :key="index" :dataId="'control' + index" :cusBoxValue="cusBoxValue" v-bind:is="'group' + item.fieldGroup" ref="controlGroup" :itemData="item" :items="formData"></component>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
import Controls from '@/page/Client/CustPublic/ControlsShow/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControlsShow/index.js'
export default {
    name: 'Detail',
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            pageUrl: '',
            billId: '',
            detailSet: [],
            optCode: 'otDetail',
            moduleCode: 'CP010',
            type: 'otDetail',
            formData: {},
            item: {}
        }
    },
    async created() {
        this.billId = this.$route.params.id
        await this.getItemData()
        this.unit()
        this.setMenu()
    },
    computed: {
    },
    mounted() {
        this.pageUrl = this.$route.path
    },
    methods: {
        async updateData(id, load) {
            if (!load) {
                this.loading = true
            }
            this.billId = id
            this.activeTab = '0'
            await this.getItemData()
            this.unit()
            this.setMenu()
        },
        setMenu() {
            let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585276758192')) // 更多
            setMenu.onClick(() => {
                this.spaceAction()
            })
        },
        async spaceAction() {
            console.log('aaaaaaa', this.detailOpt)
            try {
                let option = []
                this.detailOpt.forEach(items => {
                    if (items.optCode != 'otPrint' && items.optCode != 'otTag' && !(items.optCode == 'otNew' && items.optModuleCode == 'WO001')) {
                        items.name = items.optName
                        option.push(items)
                    }
                })
                let res = await this.Global.utils.actionSheet.open(option)
                this.optClick(res)
            } catch (error) {
                console.log(error)
            }
        },
        optClick(item) {
            console.log('嗷嗷，被触发看', item)

            this.optItem = item
            this.rightCheck(item)
        },
        rightCheck(item) {
            let _this = this
            if (item.optCode == 'otAddAttach') {
                _this.uploadDingFile()
                return
            }
            let obj = {
                moduleCode: 'CP010',
                identFieldValue: _this.billId,
                optCode: item.optCode
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: _this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_rightCheck_do, { params: obj }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    // _this.$toast.success(res.data.msg)
                    _this.$toast.clear() // 关闭遮罩
                    if (item.optCode == 'otCopy') {
                        let data = _this.item
                        _this.Global.utils.setItem('addPriceCalcObj' + item.optModuleCode, JSON.stringify(data))
                        _this.toOtherPage('/PriceCalc/add/' + item.optModuleCode + '?type=otCopy')
                    } else if (item.optCode == 'otEdit') {
                        let data = _this.item
                        _this.Global.utils.setItem('editPriceCalcObj' + item.optModuleCode, JSON.stringify(data))
                        _this.toOtherPage('/PriceCalc/edit/' + item.optModuleCode + '/' + _this.billId)
                    } else if (item.optCode == 'otDelete') {
                        _this.deletePriceCalc(obj)
                    } else {
                        _this.optOtherMsg = _this.$t('mx_mobile.Client.1627010069815') + item.optName + _this.$t('mx_mobile.Client.1627010202702') // 是否要对此客户进行  操作
                        // _this.showOther = true
                    }
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        async confirm(title, message) {
            let flag = true
            try {
                await this.$dialog.confirm({ title, message })
            } catch (error) {
                this.$toast.fail('已取消删除')
                flag = false
            }
            return flag
        },
        // 删除算价单
        async deletePriceCalc(data) {
            let flag = await this.confirm('提示', '你确定要删除吗？')
            if (!flag) {
                return
            }
            let isSuccess = await this._deletePriceCalc(data)
            if (isSuccess) {
                this.$toast.success('删除成功')
                this.$router.back()
            }
        },
        async _deletePriceCalc(data) {
            delete data?.moduleCode
            let returnflag = false
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.document_operate_detailOpt_put
                let res = await this.axios.put(url, {...data, optModuleCode: 'CP010'})
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnflag = true
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return returnflag
        },
        toOtherPage(url) {
            this.$router.push({ path: url, query: this.g_setQuery() })
        },
        unit() {
            let _this = this
            let p0 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'CP010',
                        type: 'viewSet'
                    }
                }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            let p1 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'CP010',
                    viewType: 'detail'
                }
                this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.moduleOptSet_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data.detailOpt)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p0, p1]).then(function (results) {
                const detailSet = _this.swidchEditSet(results[0])
                const curRoles = _this.companie.contactRoles
                console.log('curRoles', curRoles)
                const displayHiddenFields = ['fullCostAmt', 'compFullCostAmt']
                const displayHiddenTabFields = ['bottomCost', 'bottomCoefficient', 'silkCost1', 'silkCost2', 'silkCost3', 'silkCost4', 'silkCoefficient1', 'silkCoefficient2', 'silkCoefficient3', 'silkCoefficient4', 'maxDosage', 'maxCoefficient', 'grassa', 'grassb', 'grassc', 'grassd', 'tuftingCostTax', 'tuftingCost', 'backglueCostTax', 'backGlueCost', 'bottomPrice', 'lawnStandard', 'grassSilkSales', 'lawnSales', 'calculateWeight']
                const showFieldsRoles = [1, 2, 4, 11482, 10584]
                const showTabRoles = [1, 2, 11356]
                let displayHiddenFieldsFlag = true
                let displayHiddenTabFlag = true
                curRoles.forEach(el => {
                    if (showTabRoles.includes(el)) {
                        displayHiddenTabFlag = false
                    }
                    if (showFieldsRoles.includes(el)) {
                        displayHiddenFieldsFlag = false
                    }
                })
                detailSet.forEach(el => {
                    el.isDisplayHidden = false
                    if (displayHiddenFields.includes(el.fieldName) && displayHiddenFieldsFlag) {
                        el.isDisplayHidden = true
                    }
                    if ((displayHiddenTabFields.includes(el.fieldName) || ['详细信息2'].includes(el.cnFieldCaption)) && displayHiddenTabFlag) {
                        el.isDisplayHidden = true
                    }
                    if (el.fieldId == 222110 && el.cnFieldCaption == '详细信息1') {
                        el.isDisplayHidden = true
                    }
                    if (el.fieldId == 222113 && el.cnFieldCaption == '详细信息2') {
                        el.isDisplayHidden = true
                    }
                })
                _this.detailSet = detailSet
                _this.detailOpt = results[1]
                _this.loading = false
            })
        },
        returnGroupName(groupId) {
            switch (groupId) {
                case 1:
                    return this.$t('mx_mobile.Client.1585625326467') // '地区'
                case 3:
                    return '所属'
                case 4:
                    return this.$t('mx_mobile.Client.1585625343907') // '创建人'
                case 5:
                    return this.$t('mx_mobile.Client.1585625344676') // '修改人'
                default:
                    return ''
            }
        },
        swidchEditSet(list) { // 编辑字段数据转化
            let _this = this
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                if (element.fieldGroup !== 0) { // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldId: element.fieldId,
                            fieldGroup: element.fieldGroup,
                            cnFieldCaption: _this.returnGroupName(element.fieldGroup),
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach(function (them) {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        async getItemData() {
            let _this = this
            let data = {
                moduleCode: 'CP010',
                searchType: 'detail',
                identFieldValue: _this.billId
            }
            let res = await _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_generalOperate_get, { params: data }).catch(() => {
                _this.$router.back()
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
            if (res.data.code.toString() === _this.Global.config.RES_OK) {
                _this.formData = res.data.data
                _this.item = JSON.parse(JSON.stringify(res.data.data))
                let grassSilk = ['grassSilk1', 'grassSilk2', 'grassSilk3', 'grassSilk4']
                grassSilk.forEach(el => {
                    if (this.formData[el]) {
                        this.formData[el] = this.formData[el].split('+-*/&')[1]
                    }
                })
            } else {
                _this.$router.back()
                _this.$toast.fail(res.data.msg)
            }
        }
    },
    components: Object.assign({}, Controls, GroupControls)
}
</script>

<style lang="less" scoped>
.addVue {
    background-color: #fff;
    overflow: scroll;
    height: 100%;
    .margin-bottom(10px);
    .displayHidden {
        display: none;
    }
    .list {
        .min-height(44px);
        // .line-height(44px);
        .padding-left(16px);
        .listBox{
                border-left: 0;
                border-right: 0;
                border-top: 0;
                border-bottom: 1px solid #F4F4F4;
                display: flex;
                align-items: center;
                .min-height(44px);
                .padding-left(120px);
                position: relative;
                .title{
                    color: #909399;
                    position: absolute;
                    left: 0;
                    width: 120px;
                }
            }
        .contactTitle {
            .height(45px);
            // .line-height(45px);
            background: #f5f5f5;
            .padding-left(16px);
            .font-size(14px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-warn {
                color: red;
                font-size: 12px;
            }
        }
    }
    .list1{
        .min-height(44px);
        // .line-height(44px);
        .padding-left(16px);
        background: #F4F4F4;
        position: relative;
        .more{
            position: absolute;
            .right(14px);
        }
        .contactTitle {
            .min-height(45px);
            // .line-height(45px);
            background: #f5f5f5;
            .padding-left(16px);
            .font-size(14px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-warn {
                color: red;
                font-size: 12px;
            }
        }
    }
    .list:last-child {
        .listBox {
            border-bottom: 0;
        }
    }
    .next__follow {
        color: #909399;
        border-bottom: 1px solid #eaedef;
        .padding-left(16px);
        .next__follow-time {
            .height(30px);
            .line-height(30px);
            .padding-right(16px);
            .name {
                display: inline-block;
                .width(100px);
            }
        }
        .next__follow-desc {
            .padding-left(100px);
            &:lang(en) {
                .padding-left(130px);
            }
            .padding-right(16px);
            /deep/ .van-cell {
                .padding(10px, 0px);
            }
        }
    }
    .files__ware {
        .list {
            vertical-align: top;
            .width(75px);
            .height(75px);
            display: inline-block;
            .margin(5px);
            .margin-left(0);
            .margin-right(10px);
            line-height: 75px;
            text-align: center;
            position: relative;
            > img {
                .width(75px);
                .height(75px);
                vertical-align: top;
            }
            .iconBox {
                position: absolute;
                .width(19px);
                .line-height(14px);
                .height(19px);
                background: rgba(0, 0, 0, 0.6);
                border: 2px solid rgba(255, 255, 255, 1);
                border-radius: 50%;
                color: white;
                .font-size(12px);
                font-weight: bold;
                .right(-4px);
                .top(-4px);
            }
        }
        /deep/ .van-cell {
            .padding(10px, 16px, 10px, 0px);
        }
        /deep/ .van-collapse-item__content {
            .padding(0px, 16px, 0px, 0px);
        }
        /deep/ .van-hairline--top-bottom::after,
        .van-hairline-unset--top-bottom::after {
            border: none;
        }
        .AppItem {
            .padding-left(16px);
            .font-size(14px);
            border-bottom: 1px solid #eaedef;
        }
        .show-img-annex {
            font-size: 0;
            .iconBox-ware {
                position: relative;
                display: inline-block;
                width: 100%;
                .margin-right(10px);
                .margin-bottom(5px);
                .height(40px);
                font-size: 0;
                border: 1px solid #eaedef;
                .icon-img {
                    .width(25px);
                    .height(25px);
                    .margin-left(5px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .iconIMG,
                    .iconSVG {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: inline-block;
                        vertical-align: middle;
                        .width(25px);
                        .height(25px);
                    }
                }
                .iconfont {
                    color: #65585a;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                .contentBox {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;
                    width: calc(100% - 2rem);
                    height: 100%;
                    .margin-left(5px);
                    .content {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        & > p {
                            .height(17px);
                            .line-height(17px);
                            .font-size(12px);
                        }
                        .fileName {
                            color: #909399;
                            /*  .margin-bottom(8px); */
                        }
                        .fileInfo {
                            color: #909399;
                        }
                    }
                }
                &:last-child {
                    .contentBox {
                        border-bottom: unset !important;
                    }
                }
            }
        }
    }
    .upload_button {
        .height(45px);
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #eaedef;
        background: #fff;
        display: inline-block;
        width: 100%;
        .padding(0px, 16px);
        .upload_file {
            .margin-right(10px);
            color: #008cee;
            cursor: pointer;
            display: inline-block;
            .width(20px);
            .line-height(45px);
            .font-size(20px);
        }
    }
}
</style>
