<template>
    <div class="Detail" style="bottom:0;">
        <vueTemplate ref="vueTemplate" viewType="detail" :sysBoxValue="sysBoxValue" :currency="currency" :AONIcustSort="AONIcustSort" :cusBoxValue="cusBoxValue"></vueTemplate>
    </div>
</template>
<script>
import vueTemplate from './Vue/index.vue'
import titleMixin from '@/mixin/title'
export default {
    name: 'Detail',
    // title: '算价单信息',
    title: function fc() { return '算价单信息' },
    mixins: [titleMixin],
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            pageUrl: ''
        }
    },
    created() {
        // this.$options.title = this.$t('mx_mobile.Client.1584681452354') // 客户
    },
    mounted() {
        this.pageUrl = this.$route.path
    },
    methods: {
    },
    watch: {
        '$route': function (val, old) {
            // console.log(val.query.hasOwnProperty('stepNew'), '+++')
            if (val.path == this.pageUrl) {
                this.$refs.vueTemplate.updateData(val.params.id, true)
            } else if (val.path.indexOf('/PriceCalc/detail') != -1) {
                this.pageUrl = val.path
                this.setHeaderTitle('算价单信息')
                this.$refs.vueTemplate.updateData(val.params.id)
            }
        }
    },
    components: {
        'vueTemplate': vueTemplate
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
